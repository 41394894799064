/* USERS */
export const GET_USERS = "GET_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const GET_USERS_FAIL = "GET_USERS_FAIL"

/* USERS PROFILE */
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"

/**
 * add user
 */
export const ADD_USER = "ADD_USER"
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
export const ADD_USER_FAIL = "ADD_USER_FAIL"

/**
 * Edit user
 */
export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

/**
 * Delete user
 */
export const DELETE_USER = "DELETE_USER"
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS"
export const DELETE_USER_FAIL = "DELETE_USER_FAIL"

/**
 * Change Password
 */
export const CHANGE_PASSWORD = "CHANGE_PASSWORD"
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL"

/**
 * Get users BY Role
 */
export const GET_USERS_BY_ROLE = "GET_USERS_BY_ROLE"
export const GET_USERS_BY_ROLE_SUCCESS = "GET_USERS_BY_ROLE_SUCCESS"
export const GET_USERS_BY_ROLE_FAIL = "GET_USERS_BY_ROLE_FAIL"

/**
 * User Criteria
 */
export const USER_CRITERIA = "USER_CRITERIA"
export const USER_CRITERIA_SUCCESS = "USER_CRITERIA_SUCCESS"
export const USER_CRITERIA_FAIL = "USER_CRITERIA_FAIL"


/**
 * Reset Password
 */
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL"


import { call, put, takeEvery } from "redux-saga/effects";

// Languages Redux States
import { ADD_NEW_LANGUAGE, DELETE_LANGUAGE, GET_LANGUAGES, UPDATE_LANGUAGE } from "./actionTypes";

import {
    addLanguageFail,
    addLanguageSuccess,
    deleteLanguageFail,
    deleteLanguageSuccess,
    getLanguagesFail,
    getLanguagesSuccess,
    updateLanguageFail,
    updateLanguageSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { get, post, del } from "../../helpers/api_helper";

function* fetchLanguages() {
    try {
        const response = yield call(getLanguages);
        yield put(getLanguagesSuccess(response));
    } catch (error) {
        yield put(getLanguagesFail(error));
    }
}

const getLanguages = () => get(url.GET_LANGUAGES)

function* onUpdateLanguage({ payload: language }) {
    try {
        const response = yield call(updateLanguage, language);
        yield put(updateLanguageSuccess(response));
    } catch (error) {
        yield put(updateLanguageFail(error));
    }
}

const updateLanguage = (language) => post(url.UPDATE_LANGUAGE,language)

function* onDeleteLanguage({ id}) {
    try {
        const response = yield call(deleteLanguage, id);
        yield put(deleteLanguageSuccess(response ,id));
    } catch (error) {
        yield put(deleteLanguageFail(error));
    }
}

const deleteLanguage = (id) => del(`${url.DELETE_LANGUAGE}/${id}`);

function* onAddNewLanguage({ payload: language }) {
    try {
        const response = yield call(addNewLanguage, language);

        yield put(addLanguageSuccess(response));
    } catch (error) {
        yield put(addLanguageFail(error));
    }
}

const addNewLanguage = (language) => post(url.ADD_NEW_LANGUAGE,language);

function* languagesSaga() {
    yield takeEvery(GET_LANGUAGES, fetchLanguages);
    yield takeEvery(ADD_NEW_LANGUAGE, onAddNewLanguage);
    yield takeEvery(UPDATE_LANGUAGE, onUpdateLanguage);
    yield takeEvery(DELETE_LANGUAGE, onDeleteLanguage);
}

export default languagesSaga;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination
} from "react-table";
import { Table, Row, Col, Button, Input } from "reactstrap";

const BasicTable = ({
                      columns,
                      data,
                      isAddOpen,
                      customPageSize,
                      className,
                      handleAddClick
                    }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
      }
    },
    useSortBy,
    useExpanded,
    usePagination
  );

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  return (
    <Fragment>
      <Row className="mb-2">
        <div className="text-sm-end">
          {isAddOpen && (
            <Button
              type="button"
              color="success"
              className="btn-rounded  mb-2 me-2"
              onClick={handleAddClick}
            >
              <i className="mdi mdi-plus me-1" />
            </Button>
          )}
        </div>
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap h-10">
          {headerGroups.map(headerGroup => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th key={column.id} className={column.className}>
                  <div className="mb-0" {...column.getSortByToggleProps()}>
                    {column.render("HeaderLabel")}
                  </div>
                </th>
              ))}
            </tr>
          ))}
          </thead>

          <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr>
                  {row.cells.map(cell => {
                    return (
                      <td key={cell.id} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
          </tbody>
        </Table>
      </div>

      <Row className="justify-content-md-end justify-content-center align-items-center">
        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button
              outline
              color="secondary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              outline
              color="secondary"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-md-auto d-none d-md-block">
          {" "}
          <strong>
            {pageIndex + 1} / {pageOptions.length}
          </strong>
        </Col>
        <Col className="col-md-auto">
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={pageOptions.length}
            defaultValue={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>

        <Col className="col-md-auto">
          <div className="d-flex gap-1">
            <Button color="secondary" onClick={nextPage} disabled={!canNextPage} outline>
              {">"}
            </Button>
            <Button
              color="secondary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
              outline
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

BasicTable.propTypes = {
  preGlobalFilteredRows: PropTypes.any
};

export default BasicTable;

import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

//Import Images
import error from "../../assets/images/error-img.png";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const Pages404 = (props) => {
  //meta title
  document.title = props.t("Error");

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <Container>
          <Row>
            <Col lg="12">
              <div className="text-center mb-5">
                <h1 className="display-2 font-weight-medium">
                  4<i className="bx bx-buoy bx-spin text-primary display-3" />
                  4
                </h1>
                <h4 className="text-uppercase">{props.t("Sorry, we couldn't find that page")}</h4>
                <div className="mt-5 text-center">
                  <Link
                    className="btn btn-primary "
                    to="/"
                  >
                    {props.t("Back to Dashboard")}
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div>
                <img src={error} alt="" className="img-fluid" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
Pages404.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Pages404);

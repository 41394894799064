import {
    GET_FIRM_INFOS_FAIL,
    GET_FIRM_INFOS_SUCCESS,
    ADD_FIRM_INFO_SUCCESS,
    ADD_FIRM_INFO_FAIL,
    UPDATE_FIRM_INFO_SUCCESS,
    UPDATE_FIRM_INFO_FAIL,
    DELETE_FIRM_INFO_SUCCESS,
    DELETE_FIRM_INFO_FAIL
} from "./actionTypes";

const INIT_STATE = {
    firmInfos: [],
    error: {},
};

const FirmInfo = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_FIRM_INFOS_SUCCESS:
            return {
                ...state,
                firmInfos: action.payload,
            };

        case GET_FIRM_INFOS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case ADD_FIRM_INFO_SUCCESS:
            return {
                ...state,
                firmInfos: [...state.firmInfos, action.payload],
            };

        case ADD_FIRM_INFO_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case UPDATE_FIRM_INFO_SUCCESS:
            return {
                ...state,
                firmInfos: state.firmInfos.map(firmInfo =>
                    firmInfo.id.toString() === action.payload.id.toString()
                        ? { firmInfo, ...action.payload }
                        : firmInfo
                ),
            };

        case UPDATE_FIRM_INFO_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case DELETE_FIRM_INFO_SUCCESS:
            return {
                ...state,
                firmInfos: state.firmInfos.filter(
                    firmInfo => firmInfo.id.toString() !== action.req.toString()
                ),
            };

        case DELETE_FIRM_INFO_FAIL:
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default FirmInfo;

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, CardBody, Container, Input, Label, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";
import {
  deleteFirmInfo as onDeleteFirmInfo,
  getFirmInfos as onGetFirmInfos,
  updateFirmInfo as onUpdateFirmInfo
} from "store/firmInfo/actions";

//redux
import { useDispatch, useSelector } from "react-redux";

// Column
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import Select from "react-select";
import { currencies } from "../../constants/CurrenciesEnum";

const FirmInfo = props => {

  //meta title
  document.title = `CRM | ${props.t("Firm Info")}`;

  const dispatch = useDispatch();

  const initialFirmInfo = {
    taxNumber: "",
    taxOffice: "",
    mersisCode: "",
    isTemplate: "",
    companyType: "",
    phoneNumber: "",
    email: "",
    webSite: "",
    currency: "",
    sector: "",
    country: "",
    city: "",
    registerDate: "",
    subscriptionEndDate: "",
    currentId: "",
    address: "",
    title: "",
    userCount: "",
    storeEmail: ""
  };

  const { firmInfos, error } = useSelector(state => ({
    firmInfos: state.FirmInfo.firmInfos,
    error: state.FirmInfo.error
  }));

  const [firmInfo, setFirmInfo] = useState(initialFirmInfo);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [selectedCurr, setSelectedCurr] = useState({ label: "", value: "" });

  const handleChange = (e) => {
    setFirmInfo({
      ...firmInfo,
      [e.target.name]: e.target.value
    });
  };

  //delete firmInfo
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteFirmInfo = () => {
    if (firmInfo.id) {
      dispatch(onDeleteFirmInfo(firmInfo.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (firmInfos && firmInfos.length > 0) {
      setFirmInfo(firmInfos[0]);
      setSelectedCurr({
        label: firmInfos[0].currency || "",
        value: firmInfos[0].currency || ""
      });
    }
  }, [firmInfos]);

  useEffect(() => {
    dispatch(onGetFirmInfos());
  }, []);

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  function updateFirmInfo() {
    const updatefirmInfoReq = Object.assign({
      id: firmInfo ? firmInfo.id : 0,
      name: firmInfo.name,
      taxNumber: firmInfo.taxNumber,
      phoneNumber: firmInfo.phoneNumber,
      email: firmInfo.email,
      taxOffice: firmInfo.taxOffice,
      mersisCode: firmInfo.mersisCode,
      isTemplate: firmInfo.isTemplate,
      companyType: firmInfo.companyType,
      webSite: firmInfo.webSite,
      sector: firmInfo.sector,
      currency: firmInfo.currency,
      country: firmInfo.country,
      city: firmInfo.city,
      registerDate: firmInfo.registerDate,
      subscriptionEndDate: firmInfo.subscriptionEndDate,
      currentId: firmInfo.currentId,
      address: firmInfo.address,
      title: firmInfo.title,
      userCount: firmInfo.userCount,
      storeEmail: firmInfo.storeEmail
    });
    // update firmInfo
    dispatch(onUpdateFirmInfo(updatefirmInfoReq));
  }

  const onSelectCurr = (e) => {
    setSelectedCurr(e);
    setFirmInfo({
      ...firmInfo,
      currency: (e !== null && e.value !== null ? e.value.code : e)
    });
  };

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteFirmInfo}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Firm Info")} />
          <Row>
            <Card>
              <CardBody>
                <div className="mt-2">
                  <div className="row">
                    <div className="col-xs-6 col-md-3 mb-3">
                      <Label className="form-label">{props.t("Firm Title")}</Label>
                      <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
                      <Input
                        id="title"
                        name="title"
                        type="text"
                        onChange={handleChange}
                        value={firmInfo.title || ""}
                        required={true}
                        invalid={!firmInfo.title}
                      />
                    </div>
                    <div className="col-xs-6 col-md-3 mb-3">
                      <Label className="form-label">{props.t("Company Type")}</Label>
                      <Input
                        id="companyType"
                        name="companyType"
                        type="text"
                        onChange={handleChange}
                        value={firmInfo.companyType || ""}
                      />
                    </div>
                    <div className="col-xs-6 col-md-2 mb-3">
                      <Label className="form-label">{props.t("Sector")}</Label>
                      <Input
                        id="sector"
                        name="sector"
                        type="text"
                        onChange={handleChange}
                        value={firmInfo.sector || ""}
                      />
                    </div>
                    <div className="col-xs-6 col-md-2 mb-3">
                      <Label className="form-label">{props.t("Phone")}</Label>
                      <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
                      <Input
                        id="detailPhone"
                        name="phoneNumber"
                        type="tel"
                        onChange={handleChange}
                        value={firmInfo.phoneNumber || ""}
                        required={true}
                        placeholder="(---) --- ----"
                        invalid={!firmInfo.phoneNumber}
                      />
                    </div>
                    <div className="col-xs-6 col-md-2 mb-3">
                      <Label className="form-label">{props.t("Email")}</Label>
                      <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
                      <Input
                        id="detailEmail"
                        name="email"
                        type="email"
                        onChange={handleChange}
                        value={firmInfo.email}
                        required={true}
                        invalid={!firmInfo.email}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6 col-md-2 mb-3">
                      <Label className="form-label">{props.t("Tax Number")}</Label>
                      <Input
                        id="taxNumber"
                        name="taxNumber"
                        type="text"
                        onChange={handleChange}
                        value={firmInfo.taxNumber || ""}
                      />
                    </div>
                    <div className="col-xs-6 col-md-2 mb-3">
                      <Label className="form-label">{props.t("Current ID")}</Label>
                      <Input
                        id="currentId"
                        name="currentId"
                        type="text"
                        onChange={handleChange}
                        value={firmInfo.currentId || ""}
                      />
                    </div>
                    <div className="col-xs-6 col-md-2 mb-3">
                      <Label className="form-label">{props.t("Tax Office")}</Label>
                      <Input
                        id="taxOffice"
                        name="taxOffice"
                        type="text"
                        onChange={handleChange}
                        value={firmInfo.taxOffice || ""}
                      />
                    </div>
                    <div className="col-xs-4 col-md-2 mb-3">
                      <Label className="form-label">{props.t("Mersis Code")}</Label>
                      <Input
                        id="mersisCode"
                        name="mersisCode"
                        type="text"
                        onChange={handleChange}
                        value={firmInfo.mersisCode || ""}
                      />
                    </div>
                    <div className="col-xs-6 col-md-2 mb-3">
                      <Label className="form-label">{props.t("Register Date")}</Label>
                      <Input
                        id="registerDate"
                        name="registerDate"
                        type="date"
                        onChange={handleChange}
                        value={firmInfo.registerDate || ""}
                      />
                    </div>
                    <div className="col-xs-6 col-md-2 mb-3">
                      <Label className="form-label">{props.t("Subscription End Date")}</Label>
                      <Input
                        id="subscriptionEndDate"
                        name="subscriptionEndDate"
                        type="date"
                        onChange={handleChange}
                        value={firmInfo.subscriptionEndDate || ""}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6 col-md-2 mb-3">
                      <Label className="form-label">{props.t("Currency")}</Label>
                      <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
                      <Select
                        value={selectedCurr}
                        onChange={(event) => onSelectCurr(event)}
                        options={currencies.map(curr => {
                          return { label: curr.code, value: curr };
                        })}
                        className="bs-select"
                        isClearable={true}
                      />
                    </div>
                    <div className="col-xs-6 col-md-2 mb-3">
                      <Label className="form-label">{props.t("Web Site")}</Label>
                      <Input
                        id="webSite"
                        name="webSite"
                        type="url"
                        onChange={handleChange}
                        value={firmInfo.webSite}
                      />
                    </div>
                    <div className="col-xs-6 col-md-2 mb-3">
                      <Label className="form-label">{props.t("City")}</Label>
                      <Input
                        id="detailCityName"
                        name="city"
                        type="text"
                        onChange={handleChange}
                        value={firmInfo.city || ""}
                      />
                    </div>
                    <div className="col-xs-6 col-md-2 mb-3">
                      <Label className="form-label">{props.t("Country")}</Label>
                      <Input
                        id="country"
                        name="country"
                        type="text"
                        onChange={handleChange}
                        value={firmInfo.country || ""}
                      />
                    </div>
                    <div className="col-xs-6 col-md-2 mb-3">
                      <Label className="form-label">{props.t("Store E-Mail")}</Label>
                      <Input
                        id="detailStoreEmail"
                        name="storeEmail"
                        type="storeEmail"
                        onChange={handleChange}
                        value={firmInfo.storeEmail}
                        required={false}
                      />
                    </div>
                    <div className="col-xs-2 col-md-2 mb-3">
                      <Label className="form-label">{props.t("User Count")}</Label>
                      <Input
                        id="userCount"
                        name="userCount"
                        type="text"
                        onChange={handleChange}
                        value={firmInfo.userCount || ""}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-md-12 mb-3">
                      <Label className="form-label">{props.t("Address")}</Label>
                      <i className="mdi mdi-alert-circle-outline text-danger ms-2 font-size-16"></i>
                      <Input
                        id="address"
                        name="address"
                        type="text"
                        onChange={handleChange}
                        value={firmInfo.address || ""}
                        required={true}
                        invalid={!firmInfo.address}
                      />
                    </div>
                  </div>
                  <div className="text-end mb-3">
                    <button
                      id="detailSave"
                      className="btn btn-success save-firmInfo"
                      disabled={!(firmInfo.title && firmInfo.phoneNumber && firmInfo.email && firmInfo.address && firmInfo.currency)}
                      onClick={updateFirmInfo}
                    >
                      {props.t("Save Changes")}
                    </button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
FirmInfo.propTypes = {
  firmInfos: PropTypes.array,
  onGetFirmInfos: PropTypes.func,
  onAddNewFirmInfo: PropTypes.func,
  onDeleteFirmInfo: PropTypes.func,
  onUpdateFirmInfo: PropTypes.func
};

export default withRouter(withTranslation()(FirmInfo));

import PropTypes from "prop-types";
import React, { useState } from "react";

import { Alert, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row } from "reactstrap";

//redux
import { useDispatch, useSelector } from "react-redux";

import { Link, withRouter } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
// import images
import profile from "assets/images/techLabLogo.png";
import oneHairLogo from "../../assets/images/companyLogo.png";
import logo from "assets/images/logo.svg";
import { loginUser } from "../../store/auth/login/actions";

import ReCAPTCHA from "react-google-recaptcha";
import { withTranslation } from "react-i18next";

const Login = props => {

  //meta title
  document.title = "CRM | Giriş";

  const dispatch = useDispatch();
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "" || "",
      password: "" || ""
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Kullanıcı adı giriniz"),
      password: Yup.string().required("Şifre giriniz")
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    }
  });

  const { error } = useSelector(state => ({
    error: state.Login.error
  }));

  function onCaptchaChange(value) {
    setIsCaptchaSuccess(true);
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-secondary bg-gradient">
                  <Row>
                    <Col xs={7}>
                      <div className="text-dark p-4">
                        <p>CRM</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={oneHairLogo} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/dashboard" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {error ? <Alert color="danger">{props.t("Incorrect username or password")}</Alert> : null}

                      <div className="mb-3">
                        <Label className="form-label">{props.t("Username")}</Label>
                        <Input
                          name="username"
                          className="form-control"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            !!(validation.touched.username && validation.errors.username)
                          }
                        />
                        {validation.touched.username && validation.errors.username ? (
                          <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                        ) : null}
                      </div>

                      <div className="mb-3">
                        <Label className="form-label">{props.t("Password")}</Label>
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            !!(validation.touched.password && validation.errors.password)
                          }
                        />
                        {validation.touched.password && validation.errors.password ? (
                          <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mt-2 me-2">
                        <Col className="col"></Col>
                        <Col className="col">
                          <ReCAPTCHA
                            sitekey="6LeA1LclAAAAAHTXdnAwntROBJPIwEteF3NawcAQ"
                            onChange={onCaptchaChange}
                          />
                        </Col>
                        <Col className="col"></Col>
                      </Row>
                      <div className="mt-3 d-grid">
                        <button
                          disabled={!isCaptchaSuccessful}
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          {props.t("Login")}
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-100 text-center">
                <img src={profile} alt="" style={{ height: 40, width: 120 }} />
                <p>
                  © {new Date().getFullYear()} {" "}
                  <i className="mdi mdi-heart text-danger" />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

Login.propTypes = {
  t: PropTypes.any,
  history: PropTypes.object
};

export default withRouter(withTranslation()(Login));

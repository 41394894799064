import {
  LEAD_REPORT_BY_USER_SUCCESS,
  LEAD_REPORT_BY_USER_FAIL,
  LEAD_REPORT_BY_USER, LEAD_COUNTS_BY_SEGMENT, LEAD_COUNTS_BY_SEGMENT_SUCCESS, LEAD_COUNTS_BY_SEGMENT_FAIL
} from "./actionTypes";

export const getLeadReportByUser = ()=> ({
  type: LEAD_REPORT_BY_USER,
});

export const getLeadReportByUserSuccess = (response) => ({
  type: LEAD_REPORT_BY_USER_SUCCESS,
  payload: response
});

export const getLeadReportByUserFail = error => ({
  type: LEAD_REPORT_BY_USER_FAIL,
  payload: error
});

export const getLeadCountsReportBySegment = ()=> ({
  type: LEAD_COUNTS_BY_SEGMENT,
});

export const getLeadCountsReportBySegmentSuccess = (response) => ({
  type: LEAD_COUNTS_BY_SEGMENT_SUCCESS,
  payload: response
});

export const getLeadCountsReportBySegmentFail = error => ({
  type: LEAD_COUNTS_BY_SEGMENT_FAIL,
  payload: error
});
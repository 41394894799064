import React from "react";
import * as moment from "moment";


const formateDate = (date, format) => {
  const dateFormat = format ? format : "DD MMM Y";
  const date1 = moment(new Date(date)).format(dateFormat);
  return date1;
};
const toLowerCase1 = str => {
  return (
    str === "" || str === undefined ? "" : str.toLowerCase()
  );
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};

const Email = (cell) => {
  return cell.value ? cell.value : "";
};

const Phone = (cell) => {
  return cell.value ? cell.value : "";
};

const FirstName = (cell) => {
  return cell.value ? cell.value : "";
};

const LastName = (cell) => {
  return cell.value ? cell.value : "";
};

const Languages = (cell) => {
  return cell.value ? cell.value.map(item => `${item.name} `) : "";
};

const Status = (cell) => {
  return cell.value ? cell.value : "";
};

const CDate = (cell) => {
  return cell.value ? cell.value : "";
};


export {
  Name,
  Email,
  FirstName,
  LastName,
  Languages,
  Status,
  Phone,
  CDate
};
import { call, put, takeEvery } from "redux-saga/effects";

// Categories Redux States
import { ADD_NEW_CATEGORY, DELETE_CATEGORY, GET_CATEGORIES, UPDATE_CATEGORY } from "./actionTypes";

import {
  addCategoryFail,
  addCategorySuccess,
  deleteCategoryFail,
  deleteCategorySuccess,
  getCategoriesFail,
  getCategoriesSuccess,
  updateCategoryFail,
  updateCategorySuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchCategories() {
  try {
    const response = yield call(getCategories);
    yield put(getCategoriesSuccess(response));
  } catch (error) {
    yield put(getCategoriesFail(error));
  }
}

const getCategories = () => get(url.GET_CATEGORIES);

function* onUpdateCategory({ payload: segment }) {
  try {
    const response = yield call(updateCategory, segment);
    yield put(updateCategorySuccess(response));
  } catch (error) {
    yield put(updateCategoryFail(error));
  }
}

const updateCategory = (segment) => post(url.UPDATE_CATEGORY, segment);

function* onDeleteCategory({ id }) {
  try {
    const response = yield call(deleteCategory, id);
    yield put(deleteCategorySuccess(response, id));
  } catch (error) {
    yield put(deleteCategoryFail(error));
  }
}

const deleteCategory = (id) => del(`${url.DELETE_CATEGORY}/${id}`);

function* onAddNewCategory({ payload: segment }) {
  try {
    const response = yield call(addNewCategory, segment);
    yield put(addCategorySuccess(response));
  } catch (error) {
    yield put(addCategoryFail(error));
  }
}

const addNewCategory = (segment) => post(url.ADD_NEW_CATEGORY, segment);

function* segmentsSaga() {
  yield takeEvery(GET_CATEGORIES, fetchCategories);
  yield takeEvery(ADD_NEW_CATEGORY, onAddNewCategory);
  yield takeEvery(UPDATE_CATEGORY, onUpdateCategory);
  yield takeEvery(DELETE_CATEGORY, onDeleteCategory);
}

export default segmentsSaga;

import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ChangePassword from "./ChangePassword";

const ChangePasswordModal = props => {
  const [modal, setModal] = useState(false);
  const [user, setUser] = useState(props.user);

  useEffect(() => {
    toggleChangePassword();
  }, [props.modal]);

  const toggleChangePassword = () => {
    if (modal) {
      setModal(false);
      props.callbackModalResponse(false);
    } else {
      setModal(true);
    }
  };

  return (
    <React.Fragment>
      <Modal isOpen={modal} toggle={toggleChangePassword}>
        <ModalHeader toggle={toggleChangePassword} tag="h4">
          {props.t("Change Password")}
        </ModalHeader>
        <ModalBody>
          <ChangePassword
            user={user} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};


ChangePasswordModal.propTypes = {
  t: PropTypes.any,
  user: PropTypes.any,
  modal: PropTypes.bool,
  callbackModalResponse: PropTypes.func
};

export default withRouter(withTranslation()(ChangePasswordModal));
import { call, put, takeEvery } from "redux-saga/effects";

// Segments Redux States
import { ADD_NEW_SEGMENT, DELETE_SEGMENT, GET_SEGMENTS, UPDATE_SEGMENT } from "./actionTypes";

import {
    addSegmentFail,
    addSegmentSuccess,
    deleteSegmentFail,
    deleteSegmentSuccess,
    getSegmentsFail,
    getSegmentsSuccess,
    updateSegmentFail,
    updateSegmentSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchSegments() {
    try {
        const response = yield call(getSegments);
        yield put(getSegmentsSuccess(response));
    } catch (error) {
        yield put(getSegmentsFail(error));
    }
}

const getSegments = () => get(url.GET_SEGMENTS)

function* onUpdateSegment({ payload: segment }) {
    try {
        const response = yield call(updateSegment, segment);
        yield put(updateSegmentSuccess(response));
    } catch (error) {
        yield put(updateSegmentFail(error));
    }
}

const updateSegment = (segment) => post(url.UPDATE_SEGMENT,segment)

function* onDeleteSegment({ id }) {
    try {
        const response = yield call(deleteSegment, id);
        if (response.status === 200){
            yield put(deleteSegmentSuccess(response,id));
        }else{
            yield put(deleteSegmentFail(response));
        }
    } catch (error) {
        yield put(deleteSegmentFail(error));
    }
}

const deleteSegment = (id) => del(`${url.DELETE_SEGMENT}/${id}`);

function* onAddNewSegment({ payload: segment }) {
    try {
        const response = yield call(addNewSegment, segment);

        yield put(addSegmentSuccess(response));
    } catch (error) {
        yield put(addSegmentFail(error));
    }
}

const addNewSegment = (segment) => post(url.ADD_NEW_SEGMENT,segment);

function* segmentsSaga() {
    yield takeEvery(GET_SEGMENTS, fetchSegments);
    yield takeEvery(ADD_NEW_SEGMENT, onAddNewSegment);
    yield takeEvery(UPDATE_SEGMENT, onUpdateSegment);
    yield takeEvery(DELETE_SEGMENT, onDeleteSegment);
}

export default segmentsSaga;

import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  const [roleUser, setRoleUser] = useState(false);
  const [moderatorUser, setModeratorUser] = useState(false);

  useEffect(() => {
    const loggedInUser = JSON.parse(localStorage.getItem("authUser"));
    setRoleUser(loggedInUser && loggedInUser.role && loggedInUser.role.name === "ROLE_USER");
    setModeratorUser(loggedInUser && loggedInUser.role && loggedInUser.role.name === "ROLE_MODERATOR");
  }, [roleUser, moderatorUser]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("Menu")} </li>
            <li>
              <Link to="/dashboard">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Leads")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/leads">
                    <i className="bx bxs-detail" />
                    <span>{props.t("Leads List")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/lead-import">
                    <i className="bx bxs-layer-plus" />
                    <span>{props.t("Lead Import")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-user"></i>
                <span>{props.t("Users")}</span>
              </Link>
              <ul className="sub-menu">
                {!roleUser && (
                  <li>
                    <Link to="/users-list">
                      <i className="bx bx-user-check"></i>
                      <span>{props.t("Users List")}</span>
                    </Link>
                  </li>)
                }
                <li>
                  <Link to="/users-profile">
                    <i className="bx bxs-user-detail" />
                    <span>{props.t("User Profile")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/reminders">
                <i className="mdi mdi-calendar-alert"></i>
                <span>{props.t("Reminders")}</span>
              </Link>
            </li>

            <li>
              <Link to="/appointments">
                <i className="bx bxs-calendar-alt"></i>
                <span>{props.t("Appointments")}</span>
              </Link>
            </li>

            {!roleUser && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-adjust"></i>
                  <span>{props.t("Parameters")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/segments">
                      <i className="bx bxs-detail" />
                      <span>{props.t("Segments")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/categories">
                      <i className="bx bx-tone" />
                      <span>{props.t("Categories")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/resources">
                      <i className="bx bx-store"></i>
                      <span>{props.t("Resources")}</span>
                    </Link>
                  </li>

                  <li>
                    <Link to="/references">
                      <i className="bx bx-radar"></i>
                      <span>{props.t("References")}</span>
                    </Link>
                  </li>

                  {moderatorUser && (
                    <>
                      <li>
                        <Link to="/system-parameter">
                          <i className="bx bx-paperclip"></i>
                          <span>{props.t("System Parameters")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/languages">
                          <i className="bx bxs-landmark"></i>
                          <span>{props.t("Languages")}</span>
                        </Link>
                      </li>
                    </>
                  )}
                </ul>
              </li>
            )}

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-report"></i>
                <span>{props.t("Reports")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/reports">
                    <i className="bx bx-paperclip" />
                    <span>{props.t("User Reports")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/report-list">
                    <i className="bx bx-list-ol" />
                    <span>{props.t("Report List")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            {!roleUser && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bxs-analyse"></i>
                  <span>{props.t("Integration Settings")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/forms">
                      <i className="bx bx-file"></i>
                      <span>{props.t("Facebook Forms")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/integrationDefinition">
                      <i className="bx bx-folder"></i>
                      <span>{props.t("Integration Definition")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/integration">
                      <i className="bx bxl-facebook-circle"></i>
                      <span>{props.t("Integration")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/lead-integration-definition">
                      <i className="bx bxl-unsplash"></i>
                      <span>{props.t("Lead Integration Definition")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-adjust"></i>
                <span>{props.t("Operation")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/doctor">
                    <i className="bx bxs-star" />
                    <span>{props.t("Doctor")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/hospital">
                    <i className="bx bx-home-alt" />
                    <span>{props.t("Hospital")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/operation-category">
                    <i className="bx bx-dock-top" />
                    <span>{props.t("Operation Category")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/operation-definition">
                    <i className="bx bxs-detail" />
                    <span>{props.t("Operation Definition")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow">
                <i className="bx bxs-adjust-alt"></i>
                <span>{props.t("Offer")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/offer">
                    <i className="bx bxs-offer" />
                    <span>{props.t("Offer")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/offer-item">
                    <i className="bx bx-list-ol" />
                    <span>{props.t("Offer Item")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/description-template">
                    <i className="bx bxs-landscape" />
                    <span>{props.t("Description Template")}</span>
                  </Link>
                </li>
              </ul>
            </li>

            {!roleUser && (
              <li>
                <Link to="/#" className="has-arrow">
                  <i className="bx bx-history"></i>
                  <span>{props.t("History")}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/lead-history">
                      <i className="bx bx-user-check" />
                      <span>{props.t("Lead History")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/user-login-history">
                      <i className="bx bxs-log-in-circle" />
                      <span>{props.t("User Login History")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/facebook-login-history">
                      <i className="bx bx-log-in" />
                      <span>{props.t("Facebook Login History")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            )
            }
            {!roleUser && (
              <li>
                <Link to="/firm-info">
                  <i className="bx bx-collection"></i>
                  <span>{props.t("Firm Info")}</span>
                </Link>
              </li>
            )
            }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any
};

export default withRouter(withTranslation()(SidebarContent));

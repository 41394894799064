import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userContractsSaveAll } from "../../store/TermsAndPrivacy/actions";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, Row } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { getUserProfile } from "../../store/users/actions";
import ChangePassword from "../../components/Common/ChangePassword";

const loggedInUser = JSON.parse(localStorage.getItem("authUser"));
const Guard = props => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [showContracts, setShowContracts] = useState(false);
  const [showChangePass, setShowChangePass] = useState(false);
  const [termsReaded, setTermsReaded] = useState(false);
  const [isSend, setIsSend] = useState(false);
  const [contractTerms, setContractTerms] = useState(props.location.state.termsContract);
  const [contractPrivacy, setContractPrivacy] = useState(props.location.state.policyContract);
  const [privacyReaded, setPrivacyReaded] = useState(false);
  const [user, setUser] = useState({});
  const [termsTemplate, setTermsTemplate] = useState({ __html: props.location.state.termsContract && props.location.state.termsContract.content });
  const [policyTemplate, setPolicyTemplate] = useState({ __html: props.location.state.policyContract && props.location.state.policyContract.content });

  useEffect(() => {
    dispatch(getUserProfile(props.location.state.user));
  }, [props.location.state.user]);

  useEffect(() => {
    setContractPrivacy(props.location.state.policyContract);
  }, [props.location.state.policyContract]);

  useEffect(() => {
    setContractTerms(props.location.state.termsContract);
  }, [props.location.state.termsContract]);

  useEffect(() => {
    if ((props.location.state.policyContract !== undefined || props.location.state.termsContract !== undefined) && !isSend) {
      setShowContracts(true);
    }

    if (props.location.state.passwordChangeRequired === true) {
      setShowChangePass(true);
    }

  }, [props]);

  const { userProfile } = useSelector(state => ({
    userProfile: state.users.userProfile
  }));

  useEffect(() => {
    setUser(userProfile);
  }, [userProfile]);

  function sendApproveResponseToBackend() {
    const userTermsReq = contractTerms && termsReaded === true && Object.assign({
      user: user,
      contract: contractTerms
    });
    const userPolicyReq = contractPrivacy && privacyReaded === true && Object.assign({
      user: user,
      contract: contractPrivacy
    });

    dispatch(userContractsSaveAll([userTermsReq, userPolicyReq]));

    setShowContracts(false);
    setIsSend(true);

    if (showChangePass === false) {
      history.push("/dashboard");
    }
  }

  function toggleChangePass(val) {
    setShowChangePass(false);
    if (showContracts === false) {
      history.push("/login");
    }
  }

  return (
    <div className="page-content-full">
      {showContracts ? (
        <div className="container-lg">
          <Card>
            <CardHeader className="text-wrap text-center bg-white bg-gradient">
              <div className="avatar-md mx-auto mb-4">
                <div className="avatar-title bg-light  rounded-circle text-primary h1">
                  <i className="dripicons-contract-2"></i>
                </div>
              </div>
              <span className="text-info">
            {props.t("You have to accept Terms & Conditions, Privacy Policy to continue!")}
          </span>
            </CardHeader>
            <CardBody>
              <div className="card-body">
                <div className="text-center mb-4">
                  <Row className="p-2">
                    <div className="custom-accordion align-items-center">
                      <div className="card border-1 shadow-none mb-0">
                        {policyTemplate && (
                          <div className="row mt-2">
                            <div className="row justify-content-center">
                              <div className="col-xl-12">
                                <div className="mt-70 text-center">
                                  <span dangerouslySetInnerHTML={policyTemplate} />
                                </div>
                              </div>
                            </div>
                            <div className="text-center font-size-16">
                              <input type="checkbox"
                                     id="customCheckcolor4"
                                     onClick={() => setPrivacyReaded(!privacyReaded)}
                                     checked={privacyReaded}
                              /> {props.t("I read")} <span
                              className="font-size-20 bold text-center">{props.t("Privacy Policy")}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Row>
                  <hr />
                </div>
              </div>
            </CardBody>
            <CardBody>
              <div className="card-body">
                <div className="text-center mb-4">
                  <Row className="p-2">
                    <div className="custom-accordion align-items-center">
                      <div className="card border-1 shadow-none mb-0">
                        {termsTemplate && (
                          <div className="row mt-2">
                            <div className="row justify-content-center">
                              <div className="col-xl-12">
                                <div className="mt-70 text-center">
                                  <span dangerouslySetInnerHTML={termsTemplate} />
                                </div>
                              </div>
                            </div>
                            <div className="text-center font-size-16">
                              <input type="checkbox"
                                     id="customCheckcolor4"
                                     onClick={() => setTermsReaded(!termsReaded)}
                                     checked={termsReaded}
                              />{props.t("I read")}<span
                              className="font-size-20 bold text-center">{props.t("Terms & Conditions")}</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </Row>
                  <hr />
                </div>
              </div>
            </CardBody>
            <div className="row">
              <Button
                color="primary"
                className="btn-rounded me-2"
                onClick={() => sendApproveResponseToBackend()}
                disabled={!termsReaded || !privacyReaded}
              >{props.t("Approve")}</Button>
            </div>
          </Card>
        </div>
      ) : (showChangePass ? (
        <div className="container-lg">
          <ChangePassword user={userProfile} callbackSomething={toggleChangePass}
                          message={"To continue CRM, you must first update your password. You will then be directed to the login page to log in with your new password."} />
        </div>
      ) : (
        <div className="container-lg">
          <Link
            to="/dashboard"
            //onClick={() => goDashboard()}
          >
          </Link>
        </div>
      ))}
    </div>
  );
};

Guard.propTypes = {
  t: PropTypes.any,
  terms: PropTypes.any,
  policy: PropTypes.any,
  callbackHide: PropTypes.func,
  passwordChangeRequired: PropTypes.bool
};
export default withTranslation()(Guard);
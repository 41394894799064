import usFlag from "../assets/images/flags/us.jpg";
import turkey from "../assets/images/flags/turkey.jpg";
import bulgaria from "../assets/images/flags/bulgaria.png";
import italy from "../assets/images/flags/italy.jpg";
import germany from "../assets/images/flags/germany.jpg";
import spain from "../assets/images/flags/spain.jpg";
import russia from "../assets/images/flags/russia.jpg";
import netherland from "../assets/images/flags/netherland.png";
import arabic from "../assets/images/flags/arabic.webp";

const languages = {
  en: {
    label: "English",
    flag: usFlag,
  },
  tr: {
    label: "Turkish",
    flag: turkey,
  },
  sp: {
    label: "Spanish",
    flag: spain,
  },
  gr: {
    label: "German",
    flag: germany,
  },
  rs: {
    label: "Russian",
    flag: russia,
  },
  it: {
    label: "Italian",
    flag: italy,
  },
  nt: {
    label:"Dutch",
    flag: netherland,
  },
  ar: {
    label:"Arabic",
    flag: arabic,
  },
  bg: {
    label:"Bulgarian",
    flag: bulgaria,
  }
}

export default languages

import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import CustomDataTable from "../../components/Common/CustomDataTable";
import { getFacebookAuthLogs } from "../../store/authLog/actions";

const FacebookLoginHistory = (props) => {
  const dispatch = useDispatch();

  const [facebookLogsData, setFacebookLogsData] = useState([]);

  const { facebookLogResponse, facebookLogError } = useSelector(state => ({
    facebookLogResponse: state.authLog.facebookLogResponse,
    facebookLogError: state.authLog.facebookLogError
  }));

  useEffect(() => {
    if (facebookLogResponse !== undefined && facebookLogResponse) {
      setFacebookLogsData(facebookLogResponse);
    }
  }, [facebookLogResponse]);

  useEffect(()=>{
    dispatch(getFacebookAuthLogs());
  },[])


  const facebookDataColumns = useMemo(
    () => [
      {
        dataField: "createDate",
        text: `${props.t("Date")}`,
        sort: true
      },
      {
        dataField: "createUser.fullName",
        text: `${props.t("User")}`,
        sort: true
      },
      {
        dataField: "status",
        text: `${props.t("Status")}`,
        sort: true
      },
      {
        dataField: "type",
        text: `${props.t("Type")}`,
        sort: true
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Breadcrumbs title={props.t("User Login Histories")} />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <CardBody className="table-responsive table-light">
                    {facebookLogsData.length > 0 && (
                      <CustomDataTable
                        id="facebook-log-history"
                        title={props.t("Facebook Login History")}
                        columns={facebookDataColumns}
                        data={facebookLogsData}
                        selectRow={false}
                        export={false}
                        sortingAlg={[{
                          dataField: "createDate",
                          order: "desc"
                        }]}
                      />
                    )}
                  </CardBody>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
FacebookLoginHistory.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(FacebookLoginHistory);
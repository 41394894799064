import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../store/users/actions";
import { Alert, Col, Input, Label, Row } from "reactstrap";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Notification from "../Notification";

const ChangePassword = props => {

  const dispatch = useDispatch();

  const [password, setPassword] = useState("");
  const [passwordRetype, setPasswordRetype] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [passValid, setPassValid] = useState(false);
  const [alert, setAlert] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    setUser(props.user);
  }, [props.user]);

  const handleChangePass = (e) => {
    const regEXp = /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-99!@#$%^&*.]{8,99}$/;
    const trimmedPass = e.target.value.trim();
    setPassword(trimmedPass);
    setAlert(true);
    if (regEXp.test(trimmedPass)) {
      setPassValid(true);
      setAlert(false);
      setUser({
        ...user,
        ["password"]: trimmedPass
      });
    }
  };

  const handleChangePassRetype = (e) => {
    setPasswordRetype(e.target.value.trim());
  };

  const { changePassSuccess } = useSelector(state => ({
    changePassSuccess: state.users.changePassSuccess
  }));

  const saveChanges = () => {
    if (!passValid || user.password !== passwordRetype) {
      setMessage(`${props.t("Passwords must match and contain numbers and letters and must be 8 characters long may include (!@#$%^&*.)")}`);
      setShowNotification(true);
      setNotificationType("Warning");
    } else {
      dispatch(changePassword(user));
    }
  };

  useEffect(() => {
    if (changePassSuccess === true) {
      setPassword("");
      setPasswordRetype("");
      props.callbackSomething(true);
    }
  }, [changePassSuccess]);

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  return (
    <React.Fragment>
      <Notification
        message={message}
        show={showNotification}
        callbackShow={handleClose}
        type={notificationType}
      />
      <Row>
        <div className="avatar-lg mx-lg-auto mb-4">
          <div className="avatar-title bg-light rounded-circle text-info h1">
            <i className="dripicons-star"></i>
          </div>
        </div>
        {props.message && (<span className="text-info text-center font-size-18">
            {props.t(`${props.message}`)}
          </span>)}
      </Row>
      <Row form="true">
        <Col className="col-12">
          <div className="mb-3">
            <Label className="form-label">{props.t("Password")}</Label>
            {alert === true ? (<Alert color="warning" style={{ marginTop: "3px" }}>
              {props.t("Password must contain numbers and letters and must be 8 characters long may include (!@#$%^&*.)")}
            </Alert>) : null}
            <Input
              name="password"
              type="password"
              value={password}
              onChange={handleChangePass}
              invalid={!passValid}
              autoComplete="new-password"
            />
          </div>
          <div className="mb-3">
            <Label className="form-label">{props.t("Password Retype")}</Label>
            {passwordRetype !== password ? (<Alert color="warning" style={{ marginTop: "3px" }}>
              {props.t("Passwords do not match!!!")}
            </Alert>) : null}
            <Input
              name="passwordReType"
              type="password"
              value={passwordRetype}
              onChange={handleChangePassRetype}
              invalid={passwordRetype !== password}
              autoComplete="new-password"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <button
              type="submit"
              className="btn btn-success save-lead"
              onClick={saveChanges}
            >
              {props.t("Save")}
            </button>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};


ChangePassword.propTypes = {
  t: PropTypes.any,
  user: PropTypes.any,
  modal: PropTypes.bool,
  callbackModalResponse: PropTypes.func,
  callbackSomething: PropTypes.func,
  message: PropTypes.any
};

export default withRouter(withTranslation()(ChangePassword));
import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";
import {
  addNewLanguage as onAddNewLanguage,
  deleteLanguage as onDeleteLanguage,
  getLanguages as onGetLanguages,
  updateLanguage as onUpdateLanguage
} from "store/language/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Code, Description, Name } from "./languageCol";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";

const Languages = props => {

  //meta title
  document.title = `CRM | ${props.t("Languages")}`;

  const dispatch = useDispatch();

  const { languages, error } = useSelector(state => ({
    languages: state.Languages.languages,
    error: state.Languages.error
  }));

  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [language, setLanguage] = useState(null);
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");


  // validation
  const validation = {
    values: {
      name: (language && language.name) || "",
      code: (language && language.code) || "",
      description: (language && language.description) || ""
    },
    handleSubmit: (values) => {
      if (isEdit) {
        const updateLanguage = {
          id: language ? language.id : 0,
          name: values.name,
          code: values.code,
          description: values.description
        };
        // update language
        dispatch(onUpdateLanguage(updateLanguage));
      } else {
        const newLanguage = {
          name: values["name"],
          code: values["code"],
          description: values["description"]
        };
        // save new language
        dispatch(onAddNewLanguage(newLanguage));
      }
      toggle();
    }
  };

  const handleLanguageClick = arg => {
    const language = arg;

    setLanguage({
      id: language.id,
      name: language.name,
      code: language.code,
      description: language.description
    });

    setIsEdit(true);
    toggle();
  };

  // Customber Column
  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        Header: "İsim",
        accessor: "name",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Code")}`,
        Header: "Kod",
        accessor: "code",
        filterable: true,
        Cell: (cellProps) => {
          return <Code {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        Header: "Açıklama",
        accessor: "description",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const languageData = cellProps.row.original;
                  handleLanguageClick(languageData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const languageData = cellProps.row.original;
                  onClickDelete(languageData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const toggle = () => {
    if (modal) {
      setModal(false);
      setLanguage(null);
    } else {
      setModal(true);
    }
  };

  //delete language
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (language) => {
    setLanguage(language);
    setDeleteModal(true);
  };

  const handleDeleteLanguage = () => {
    if (language.id) {
      dispatch(onDeleteLanguage(language.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetLanguages());
      setFirstCall(false);
    }
  }, [ languages]);

  const handleLanguageClicks = () => {
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetLanguages());
  };

  const handleChange = (e) => {
    setLanguage({
      ...language,
      [e.target.name]: e.target.value
    });
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteLanguage}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Languages")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={languages}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleLanguageClicks}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Name")}</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.name || ""}
                                invalid={!validation.values.name}
                                required={true}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Code")}</Label>
                              <Input
                                name="code"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.code || ""}
                                invalid={!validation.values.code}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Description")}</Label>
                              <Input
                                name="description"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.description || ""}
                                invalid={!validation.values.description}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-lead"
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
Languages.propTypes = {
  languages: PropTypes.array,
  onGetLanguages: PropTypes.func,
  onAddNewLanguage: PropTypes.func,
  onDeleteLanguage: PropTypes.func,
  onUpdateLanguage: PropTypes.func
};

export default withRouter(withTranslation()(Languages));

import { call, put, takeEvery } from "redux-saga/effects";

// FacebookForms Redux States
import { ADD_NEW_FACEBOOK_FORM, DELETE_FACEBOOK_FORM, GET_FACEBOOK_FORMS, UPDATE_FACEBOOK_FORM } from "./actionTypes";

import {
    addFacebookFormFail,
    addFacebookFormSuccess,
    deleteFacebookFormFail,
    deleteFacebookFormSuccess,
    getFacebookFormsFail,
    getFacebookFormsSuccess,
    updateFacebookFormFail,
    updateFacebookFormSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";

function* fetchFacebookForms() {
    try {
        const response = yield call(getFacebookForms);
        yield put(getFacebookFormsSuccess(response));
    } catch (error) {
        yield put(getFacebookFormsFail(error));
    }
}

const getFacebookForms = () => get(url.GET_FACEBOOK_FORMS)

function* onUpdateFacebookForm({ payload: facebookForm }) {
    try {
        const response = yield call(updateFacebookForm, facebookForm);
        yield put(updateFacebookFormSuccess(response));
    } catch (error) {
        yield put(updateFacebookFormFail(error));
    }
}

const updateFacebookForm = (facebookForm) => post(url.UPDATE_FACEBOOK_FORM,facebookForm)

function* onDeleteFacebookForm({ id }) {
    try {
        const response = yield call(deleteFacebookForm, id);
        if (response.status === 200){
            yield put(deleteFacebookFormSuccess(response,id));
        }else{
            yield put(deleteFacebookFormFail(response));
        }
    } catch (error) {
        yield put(deleteFacebookFormFail(error));
    }
}

const deleteFacebookForm = (id) => del(`${url.DELETE_FACEBOOK_FORM}/${id}`);

function* onAddNewFacebookForm({ payload: facebookForm }) {
    try {
        const response = yield call(addNewFacebookForm, facebookForm);

        yield put(addFacebookFormSuccess(response));
    } catch (error) {
        yield put(addFacebookFormFail(error));
    }
}

const addNewFacebookForm = (facebookForm) => post(url.ADD_NEW_FACEBOOK_FORM,facebookForm);

function* facebookFormsSaga() {
    yield takeEvery(GET_FACEBOOK_FORMS, fetchFacebookForms);
    yield takeEvery(ADD_NEW_FACEBOOK_FORM, onAddNewFacebookForm);
    yield takeEvery(UPDATE_FACEBOOK_FORM, onUpdateFacebookForm);
    yield takeEvery(DELETE_FACEBOOK_FORM, onDeleteFacebookForm);
}

export default facebookFormsSaga;

import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import usersSaga from "./users/saga";
import segmentsSaga from "./segment/saga";
import categoriesSaga from "./category/saga";
import resourcesSaga from "./resource/saga";
import dashboardSaga from "./dashboard/saga";
import rolesSaga from "./roles/saga";
import notesSaga from "./note/saga";
import importSaga from "./import/saga";
import leadsSaga from "./leads/saga";
import referencesSaga from "./reference/saga";
import appointmentsSaga from "./appointment/saga";
import criteriaSaga from "./Criteria/saga";
import reportSaga from "./report/saga";
import languageSaga from "./language/saga";
import integrationDefinitionSaga from "./IntegrationDefinition/saga";
import platformsSaga from "./platform/saga";
import remindersSaga from "./reminder/saga";
import parametersSaga from "./parameter/saga";
import historySaga from "./history/saga";
import UserTermsAndPolicySaga from "./TermsAndPrivacy/saga";
import facebookFormsSaga from "./FacebookForms/saga";
import authLogSaga from "./authLog/saga";
import leadIntegrationDefinitionsSaga from "./leadIntegrationDefinition/saga";
import doctorsSaga from "./doctor/saga";
import hospitalsSaga from "./hospital/saga";
import operationCategoriesSaga from "./operationCategory/saga";
import operationDefinitionsSaga from "./operationDefinition/saga";
import medicalOperationSaga from "./medicalOperation/saga";
import offerSaga from "./offer/saga";
import offerItemSaga from "./offerItem/saga";
import descriptionTemplatesSaga from "./descriptionTemplate/saga";
import firmInfoSaga from "./firmInfo/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(leadsSaga),
    fork(usersSaga),
    fork(categoriesSaga),
    fork(resourcesSaga),
    fork(segmentsSaga),
    fork(dashboardSaga),
    fork(rolesSaga),
    fork(notesSaga),
    fork(importSaga),
    fork(referencesSaga),
    fork(appointmentsSaga),
    fork(criteriaSaga),
    fork(reportSaga),
    fork(languageSaga),
    fork(integrationDefinitionSaga),
    fork(platformsSaga),
    fork(remindersSaga),
    fork(parametersSaga),
    fork(historySaga),
    fork(UserTermsAndPolicySaga),
    fork(facebookFormsSaga),
    fork(authLogSaga),
    fork(leadIntegrationDefinitionsSaga),
    fork(doctorsSaga),
    fork(hospitalsSaga),
    fork(operationDefinitionsSaga),
    fork(operationCategoriesSaga),
    fork(medicalOperationSaga),
    fork(offerSaga),
    fork(offerItemSaga),
    fork(descriptionTemplatesSaga),
    fork(firmInfoSaga)
  ])
}

import { call, put, takeEvery } from "redux-saga/effects";

// References Redux States
import { ADD_NEW_REFERENCE, DELETE_REFERENCE, GET_REFERENCES, UPDATE_REFERENCE } from "./actionTypes";

import {
    addReferenceFail,
    addReferenceSuccess,
    deleteReferenceFail,
    deleteReferenceSuccess,
    getReferencesFail,
    getReferencesSuccess,
    updateReferenceFail,
    updateReferenceSuccess
} from "./actions";
import * as url from "../../helpers/url_helper";
import { del, get, post } from "../../helpers/api_helper";
import { deleteSegmentFail } from "../segment/actions";

function* fetchReferences() {
    try {
        const response = yield call(getReferences);
        yield put(getReferencesSuccess(response));
    } catch (error) {
        yield put(getReferencesFail(error));
    }
}

const getReferences = () => get(url.GET_REFERENCES)

function* onUpdateReference({ payload: reference }) {
    try {
        const response = yield call(updateReference, reference);
        yield put(updateReferenceSuccess(response));
    } catch (error) {
        yield put(updateReferenceFail(error));
    }
}

const updateReference = (reference) => post(url.UPDATE_REFERENCE,reference)

function* onDeleteReference({id}) {
    try {
        const response = yield call(deleteReference, id);
        if (response.status === 200){
            yield put(deleteReferenceSuccess(response,id));
        }else{
            yield put(deleteReferenceFail(response));
        }
    } catch (error) {
        yield put(deleteReferenceFail(error));
    }
}

const deleteReference = (id) => del(`${url.DELETE_REFERENCE}/${id}`);

function* onAddNewReference({ payload: reference }) {
    try {
        const response = yield call(addNewReference, reference);

        yield put(addReferenceSuccess(response));
    } catch (error) {
        yield put(addReferenceFail(error));
    }
}

const addNewReference = (reference) => post(url.ADD_NEW_REFERENCE,reference);

function* referencesSaga() {
    yield takeEvery(GET_REFERENCES, fetchReferences);
    yield takeEvery(ADD_NEW_REFERENCE, onAddNewReference);
    yield takeEvery(UPDATE_REFERENCE, onUpdateReference);
    yield takeEvery(DELETE_REFERENCE, onDeleteReference);
}

export default referencesSaga;

const colorArray = "[\"#5045f6\", \"#4777f5\",\"#44aff0\",\"#45dbf7\",\"#f6d54a\",\"#f69846\",\"#ff4343\",\"#f845f1\", \"#ad46f3\",\"#f1c232\",\"#45dbf7\",\"#44aff0\",\"#45dbf7\",\"#f6d54a\",\"#94b585\",\"#5045f6\", \"#4777f5\",\"#44aff0\",\"#45dbf7\",\"#f6d54a\",\"#f69846\",\"#ff4343\",\"#f845f1\", \"#ad46f3\",\"#f1c232\",\"#45dbf7\",\"#44aff0\",\"#45dbf7\",\"#f6d54a\",\"#94b585\"]";

const getChartColorsArray = () => {
  const colors = JSON.parse(colorArray);
  return colors.map(function(value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);

      if (color.indexOf("#") !== -1)
        color = color.replace(" ", "");
      if (color) return color;
      else return newValue;
    } else {
      var val = value.split(",");
      if (val.length === 2) {
        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
};

export { colorArray, getChartColorsArray };
import {
  GET_USER_POLICY_FAIL,
  GET_USER_POLICY_SUCCESS,
  GET_USER_POLICY,
  GET_USER_TERMS_SUCCESS,
  GET_USER_TERMS_FAIL,
  GET_USER_TERMS,
  POST_USER_POLICY_FAIL,
  POST_USER_TERMS,
  POST_USER_TERMS_FAIL,
  POST_USER_TERMS_SUCCESS,
  POST_USER_POLICY_SUCCESS,
  POST_USER_POLICY,
  GET_LATEST_CONTRACTS,
  GET_LATEST_CONTRACTS_SUCCESS,
  GET_LATEST_CONTRACTS_FAIL,
  SAVE_ALL_CONTRACTS_SUCCESS, SAVE_ALL_CONTRACTS_FAIL
} from "./actionTypes";

const INIT_STATE = {
  termsResponse : {},
  policyResponse: {},
  contracts:[],
  error: {}
};

const TermsAndPolicy = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_TERMS_SUCCESS:
      return {
        ...state,
        termsResponse: action.payload
      };

    case GET_USER_TERMS_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case GET_USER_POLICY_SUCCESS:
      return {
        ...state,
        policyResponse: action.payload
      };

    case GET_USER_POLICY_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case POST_USER_POLICY:
      return {
        ...state,
       policyResponse: action.payload
      };

    case POST_USER_POLICY_SUCCESS:
      return {
        ...state,
        policyResponse: action.payload
      };

    case POST_USER_POLICY_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case POST_USER_TERMS_SUCCESS:
      return {
        ...state,
        termsResponse: action.payload
      };

    case POST_USER_TERMS_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case GET_LATEST_CONTRACTS_SUCCESS:
      return {
        ...state,
        contracts: action.payload
      };

    case GET_LATEST_CONTRACTS_FAIL:
      return {
        ...state,
        error: action.payload
      };
    default:
      return state;
  }
};

export default TermsAndPolicy;

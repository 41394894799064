import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";
import {
  addNewMedicalOperation as onAddNewMedicalOperation,
  deleteMedicalOperation as onDeleteMedicalOperation,
  getMedicalOperations as onGetMedicalOperations,
  updateMedicalOperation as onUpdateMedicalOperation
} from "store/medicalOperation/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Description, Name } from "./MedicalOperationCol";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import { getDoctors } from "../../store/doctor/actions";
import { getHospitals } from "../../store/hospital/actions";
import { getOperationDefinitions } from "../../store/operationDefinition/actions";

const MedicalOperation = props => {

  //meta title
  document.title = `CRM | ${props.t("Medical Operation")}`;

  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [medicalOperationList, setMedicalOperationList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [medicalOperation, setMedicalOperation] = useState(null);
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [doctor, setDoctor] = useState();
  const [hospital, setHospital] = useState();
  const [operationDefinition, setOperationDefinition] = useState();

  const validation = {
    values: {
      doctor: (medicalOperation && medicalOperation.doctor) || "",
      hospital: (medicalOperation && medicalOperation.hospital) || "",
      operationDefinition: (medicalOperation && medicalOperation.operationDefinition) || "",
      operationDate: (medicalOperation && medicalOperation.operationDate) || "",
      hospitalizationStartDate: (medicalOperation && medicalOperation.hospitalizationStartDate) || "",
      hospitalizationEndDate: (medicalOperation && medicalOperation.hospitalizationEndDate) || "",
      description: (medicalOperation && medicalOperation.description) || ""
    },

    handleSubmit: (values) => {
      if (isEdit) {
        const updateMedicalOperation = {
          id: medicalOperation ? medicalOperation.id : 0,
          doctor: values.doctor,
          hospital: values.hospital,
          operationDefinition: values.operationDefinition,
          operationDate: values.operationDate,
          hospitalizationStartDate: values.hospitalizationStartDate,
          hospitalizationEndDate: values.hospitalizationEndDate,
          description: values.description
        };
        // update medicalOperation
        dispatch(onUpdateMedicalOperation(updateMedicalOperation));
      } else {
        const newMedicalOperation = {
          doctor: values["doctor"],
          hospital: values["hospital"],
          operationDefinition: values["operationDefinition"],
          operationDate: values["operationDate"],
          hospitalizationStartDate: values["hospitalizationStartDate"],
          hospitalizationEndDate: values["hospitalizationEndDate"],
          description: values["description"]
        };
        // save new medicalOperation
        dispatch(onAddNewMedicalOperation(newMedicalOperation));
      }
      toggle();
    }
  };

  const handleMedicalOperationClick = arg => {
    const medicalOperation = arg;

    setMedicalOperation({
      id: medicalOperation.id,
      doctor: medicalOperation.doctor,
      hospital: medicalOperation.hospital,
      operationDefinition: medicalOperation.operationDefinition,
      operationDate: medicalOperation.operationDate,
      hospitalizationStartDate: medicalOperation.hospitalizationStartDate,
      hospitalizationEndDate: medicalOperation.hospitalizationEndDate,
      description: medicalOperation.description
    });

    setIsEdit(true);
    toggle();
  };

  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Doctor")}`,
        Header: "Doctor",
        accessor: "doctor.fullName",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Hospital")}`,
        Header: "Hospital",
        accessor: "hospital.name",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Operation Date")}`,
        Header: "OperationStart",
        accessor: "operationDate",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Hospitalization Date Start")}`,
        Header: "hospitalizationStartDate",
        accessor: "hospitalizationStartDate",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Hospitalization Date End")}`,
        Header: "Hospitalization End",
        accessor: "hospitalizationEndDate",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Description")}`,
        Header: "Açıklama",
        accessor: "description",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const medicalOperationData = cellProps.row.original;
                  onClickDelete(medicalOperationData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );
//Action column
  {/*
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const medicalOperationData = cellProps.row.original;
                  handleMedicalOperationClick(medicalOperationData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const medicalOperationData = cellProps.row.original;
                  onClickDelete(medicalOperationData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }*/}

  const { medicalOperations, error } = useSelector(state => ({
    medicalOperations: state.MedicalOperation.medicalOperations,
    error: state.MedicalOperation.error
  }));

  const { hospitals, errorHospital } = useSelector(state => ({
    hospitals: state.Hospital.hospitals,
    errorHospital: state.Hospital.error
  }));

  const { doctors, errorDoctor } = useSelector(state => ({
    doctors: state.Doctor.doctors,
    errorDoctor: state.Doctor.error
  }));

  const { operationDefinitions } = useSelector(state => ({
    operationDefinitions: state.OperationDefinition.operationDefinitions
  }));

  const hospitalOptions = [];
  hospitals.forEach((item) => {
    hospitalOptions.push({ label: item.name, value: item });
  });

  const doctorOptions = [];
  doctors.forEach((item) => {
    doctorOptions.push({ label: item.fullName, value: item });
  });


  const operationDefinitionOptions = [];
  operationDefinitions.forEach((item) => {
    operationDefinitionOptions.push({ label: item.name, value: item });
  });

  const handleChange = (e) => {
    setMedicalOperation({
      ...medicalOperation,
      [e.target.name]: e.target.value
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setMedicalOperation(null);

    } else {
      setModal(true);
    }
  };

  //delete medicalOperation
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (medicalOperation) => {
    setMedicalOperation(medicalOperation);
    setDeleteModal(true);
  };

  const handleDeleteMedicalOperation = () => {
    if (medicalOperation.id) {
      dispatch(onDeleteMedicalOperation(medicalOperation.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetMedicalOperations());
      setFirstCall(false);
    }
  }, [dispatch, medicalOperations]);

  useEffect(() => {
    if (firstCall) {
      dispatch(getDoctors());
      setFirstCall(false);
    }
  }, []);

  useEffect(() => {
    if (firstCall) {
      dispatch(getHospitals());
      setFirstCall(false);
    }
  }, []);

  useEffect(() => {
    if (firstCall) {
      dispatch(getOperationDefinitions());
      setFirstCall(false);
    }
  }, []);

  useEffect(() => {
    setMedicalOperationList(medicalOperations);
  }, [medicalOperations]);

  useEffect(() => {
    if (!isEmpty(medicalOperations)) {
      setMedicalOperationList(medicalOperations);
    }
  }, [medicalOperations]);

  const handleMedicalOperationClicks = () => {
    setMedicalOperationList("");
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetMedicalOperations());
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);

  function handleChangeDoctor(event) {
    setDoctor(event);
    setMedicalOperation({
      ...medicalOperation,
      ["doctor"]: event.value
    });
  }

  function handleChangeHospital(event) {
    setHospital(event);
    setMedicalOperation({
      ...medicalOperation,
      ["hospital"]: event.value
    });
  }

  function handleChangeOperationDefinition(event) {
    setOperationDefinition(event);
    setMedicalOperation({
      ...medicalOperation,
      ["operationDefinition"]: event.value
    });
  }

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteMedicalOperation}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Operation")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={medicalOperations}
                    isGlobalFilter={true}
                    //    isAddOptions={true}
                    //    handleAddClick={handleMedicalOperationClicks}
                    customPageSize={25}
                    className="custom-header-css"
                  />
                  {/*
                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Doctor")}</Label>
                              <Select
                                value={doctor}
                                onChange={(event) => handleChangeDoctor(event)}
                                options={doctorOptions}
                                className="select2-selection"
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Hospital")}</Label>
                              <Select
                                value={hospital}
                                onChange={(event) => handleChangeHospital(event)}
                                options={hospitalOptions}
                                className="select2-selection"
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Operation Definition")}</Label>
                              <Select
                                value={operationDefinition}
                                onChange={(event) => handleChangeOperationDefinition(event)}
                                options={operationDefinitionOptions}
                                className="select2-selection"
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Description")}</Label>
                              <Input
                                name="description"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.description || ""}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Operation Date")}</Label>
                              <Input
                                id="operationDate"
                                name="operationDate"
                                type="date"
                                onChange={handleChange}
                                value={validation.values.operationDate || ""}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Hospitalization Date Start")}</Label>
                              <Input
                                id="hospitalizationStartDate"
                                name="hospitalizationStartDate"
                                type="date"
                                onChange={handleChange}
                                value={validation.values.hospitalizationStartDate || ""}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Hospitalization Date End")}</Label>
                              <Input
                                id="hospitalizationEndDate"
                                name="hospitalizationEndDate"
                                type="date"
                                onChange={handleChange}
                                value={validation.values.hospitalizationEndDate || ""}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-lead"
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                  */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
MedicalOperation.propTypes = {
  medicalOperations: PropTypes.array,
  onGetmedicalOperations: PropTypes.func,
  onAddNewMedicalOperation: PropTypes.func,
  onDeleteMedicalOperation: PropTypes.func,
  onUpdateMedicalOperation: PropTypes.func
};

export default withRouter(withTranslation()(MedicalOperation));

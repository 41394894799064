import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";
import {
  addNewParameter as onAddNewParameter,
  deleteParameter as onDeleteParameter,
  getParameters as onGetParameters,
  updateParameter as onUpdateParameter
} from "store/parameter/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Code, Description, Value } from "./parameterCol";
import { withTranslation } from "react-i18next";

const SystemParameter = props => {

  //meta title
  document.title= `CRM | ${props.t("System Parameters")}`;;

  const dispatch = useDispatch();

  const { parameters } = useSelector(state => ({
    parameters: state.Parameter.parameters,
  }));

  const [modal, setModal] = useState(false);
  const [parameterList, setParameterList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [parameter, setParameter] = useState(null);
  const [firstCall, setFirstCall] = useState(true);

  // validation
  const validation ={

    values: {
      value: (parameter && parameter.value) || '',
      code: (parameter && parameter.code) || '',
      description: (parameter && parameter.description) || '',
    },
    handleSubmit: (values) => {
      if (isEdit) {
        const updateParameter = {
          id: parameter ? parameter.id : 0,
          value: values.value,
          code: values.code,
          description:values.description,
        };
        // update parameter
        dispatch(onUpdateParameter(updateParameter));
      } else {
        const newParameter = {
          value: values["value"],
          code: values["code"],
          description:values["description"],
        };
        // save new parameter
        dispatch(onAddNewParameter(newParameter));
      }
      toggle();
    },
  };

  const handleParameterClick = arg => {
    const parameter = arg;

    setParameter({
      id: parameter.id,
      value: parameter.value,
      code: parameter.code,
      description: parameter.description,
    });

    setIsEdit(true);
    toggle();
  };

  // Customber Column
  const columns = useMemo(
    () => [
      {
        HeaderLabel:`${props.t("Code")}`,
        Header: 'Kod',
        accessor: 'code',
        filterable: true,
        Cell: (cellProps) => {
          return <Code {...cellProps} />;
        }
      },
      {
        HeaderLabel:`${props.t("Value")}`,
        Header: 'Değer',
        accessor: 'value',
        filterable: true,
        Cell: (cellProps) => {
          return <Value {...cellProps} />;
        }
      },
      {
        HeaderLabel:`${props.t("Description")}`,
        Header: 'Açıklama',
        accessor: 'description',
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel:`${props.t("isDeletable")}`,
        Header: 'Silinebilirlik',
        accessor: 'deletable',
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel:`${props.t("Action")}`,
        Header: 'Action',
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const parameterData = cellProps.row.original;
                  handleParameterClick(parameterData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const parameterData = cellProps.row.original;
                  onClickDelete(parameterData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      },
    ],
    []
  );

  const handleChange = (e) => {
    setParameter({
      ...parameter,
      [e.target.name]: e.target.value
    });
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
      setParameter(null);
    } else {
      setModal(true);
    }
  };

  //delete parameter
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (parameter) => {
    setParameter(parameter);
    setDeleteModal(true);
  };

  const handleDeleteParameter = () => {
    if (parameter.id) {
      dispatch(onDeleteParameter(parameter.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetParameters());
      setFirstCall(false);
    }
  }, [dispatch, parameters]);

  useEffect(() => {
    setParameterList(parameters);
  }, [parameters]);

  useEffect(() => {
    if (!isEmpty(parameters)) {
      setParameterList(parameters);
    }
  }, [parameters]);

  const handleParameterClicks = () => {
    setParameterList("");
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetParameters());
  }

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteParameter}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={props.t("System Parameters")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    data={parameters}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleParameterClicks}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Code")}</Label>
                              <Input
                                name="code"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.code || ""}
                                invalid={!validation.values.code}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Name")}</Label>
                              <Input
                                name="value"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.value || ""}
                                invalid={!validation.values.value}
                                required={true}
                              />
                            </div>
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Description")}</Label>
                              <Input
                                name="description"
                                type="text"
                                onChange={handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-lead"
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

SystemParameter.propTypes = {
  parameters: PropTypes.array,
  onGetParameters: PropTypes.func,
  onAddNewParameter: PropTypes.func,
  onDeleteParameter: PropTypes.func,
  onUpdateParameter: PropTypes.func,
};

export default withRouter(withTranslation()(SystemParameter));

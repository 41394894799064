import React from "react";
import { Link } from "react-router-dom";

const CustId = (cell) => {
  return (
    <Link to="#" className="text-body fw-bold">{cell.value ? cell.value : ""}</Link>
  );
};

const Name = (cell) => {
  return cell.value ? cell.value : "";
};

const Surname = (cell) => {
  return cell.value ? cell.value : "";
};

const Phone = (cell) => {
  return cell.value ? "*******"+cell.value.slice(cell.value.length-4,cell.value.length) : "";
};

const Address = (cell) => {
  return cell.value ? cell.value : "";
};

const Email = (cell) => {
  return cell.value ? cell.value : "";
};

const CreateDate = (cell) => {
  return cell.value ? cell.value : "";
};

const UpdateDate = (cell) => {
  return cell.value ? cell.value : "";
};

const FacebookDate = (cell) => {
  return cell.value ? cell.value.split("T")[0] : "";
};

const BirthDate = (cell) => {
  return cell.value ? cell.value : "";
};

const Reference = (cell) => {
  return cell.value ? cell.value : "";
};

const Note = (cell) => {
  return cell.value ? cell.value : "";
};

const AppointmentDesc = (cell) => {
  return cell.value ? cell.value : "";
};

const Represent = (cell) => {
  return cell.value ? cell.value : "";
};

const City = (cell) => {
  return cell.value ? cell.value : "";
};

const Segment = (cell) => {
  return cell.value ? cell.value : "";
};

const Category = (cell) => {
  return cell.value ? cell.value : "";
};

const Resource = (cell) => {
  return cell.value ? cell.value : "";
};

const CreateUser = (cell) => {
  return cell.value ? cell.value : "";
};

const ReminderDesc = (cell) => {
  return cell.value ? cell.value : "";
};

const FormName = (cell) => {
  return cell.value ? cell.value : "";
};


export {
  CustId,
  Name,
  Surname,
  Phone,
  Address,
  CreateDate,
  UpdateDate,
  FacebookDate,
  Email,
  BirthDate,
  Reference,
  Represent,
  Note,
  City,
  Segment,
  Category,
  Resource,
  CreateUser,
  AppointmentDesc,
  ReminderDesc,
  FormName
};
import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import WarningModal from "../../components/Common/WarningModal";
import {
  addNewOffer as onAddNewOffer,
  deleteOffer as onDeleteOffer,
  getOffers as onGetOffers,
  updateOffer as onUpdateOffer
} from "store/offer/actions";

//redux
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "../../components/Common/TableContainer";

// Column
import { Currency, DefaultPrice, Description, Name, TotalPrice } from "./offerCol";
import { withTranslation } from "react-i18next";
import Notification from "../../components/Notification";
import { getTemplateOfferItems as onGetOfferItems } from "../../store/offerItem/actions";

import Select from "react-select";
import { currencies } from "../../constants/CurrenciesEnum";
import { getDescriptionTemplates as onGetDescriptionTemplate } from "../../store/descriptionTemplate/actions";

const Offer = props => {

  //meta title
  document.title = `CRM | ${props.t("Offer")}`;

  const dispatch = useDispatch();

  const initialOfferItem = {
    name: "",
    totalAmount: 0,
    amount: 0,
    taxAmount: 0,
    taxPercentage: 0
  };

  const { offers, error } = useSelector(state => ({
    offers: state.Offer.offers,
    error: state.Offer.error
  }));

  const [modal, setModal] = useState(false);
  const [offerList, setOfferList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [offer, setOffer] = useState(null);
  const [firstCall, setFirstCall] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [message, setMessage] = useState("");
  const [notificationType, setNotificationType] = useState("Info");
  const [isTemplate, setIsTemplate] = useState(false);
  const [selectedCurr, setSelectedCurr] = useState({ label: "", value: "" });
  const [offerItem, setOfferItem] = useState(initialOfferItem);
  const [selectedOfferItem, setSelectedOfferItem] = useState(null);
  const [selectedTax, setSelectedTax] = useState({ label: "", value: "" });
  const [descriptionTemplate, setDescriptionTemplate] = useState();

  // validation
  const validation = {

    values: {
      name: (offer && offer.name) || "",
      title: (offer && offer.title) || "",
      amount: (offer && offer.amount) || "",
      totalAmount: (offer && offer.totalAmount) || "",
      taxAmount: (offer && offer.taxAmount) || "",
      currency: (offer && offer.currency) || "",
      description: (offer && offer.description) || "",
      descriptionTemplate: (offer && offer.descriptionTemplate) || null,
      offerItems: (offer && offer.offerItems) || "",
      isTemplate: (offer && offer.isTemplate) || ""
    },
    handleSubmit: (values) => {
      if (isEdit) {
        const updateOffer = {
          id: offer ? offer.id : 0,
          name: values.name,
          title: values.title,
          amount: values.amount,
          totalAmount: values.totalAmount,
          taxAmount: values.taxAmount,
          currency: values.currency,
          description: values.description,
          descriptionTemplate: values.descriptionTemplate,
          offerItems: values.offerItems,
          isTemplate: values.isTemplate
        };
        // update offer
        dispatch(onUpdateOffer(updateOffer));
      } else {
        const newOffer = {
          name: values["name"],
          title: values["title"],
          amount: values["amount"],
          totalAmount: values["totalAmount"],
          taxAmount: values["taxAmount"],
          currency: values["currency"],
          description: values["description"],
          descriptionTemplate: values["descriptionTemplate"],
          offerItems: values["offerItems"],
          isTemplate: values["isTemplate"]
        };
        // save new offer
        dispatch(onAddNewOffer(newOffer));
      }
      toggle();
    }
  };

  const handleOfferClick = arg => {
    const offer = arg;

    setOffer({
      id: offer.id,
      name: offer.name,
      title: offer.title,
      amount: offer.amount,
      totalAmount: offer.totalAmount,
      taxAmount: offer.taxAmount,
      currency: offer.currency,
      description: offer.description,
      descriptionTemplate: offer.descriptionTemplate,
      offerItems: offer.offerItems,
      isTemplate: offer.isTemplate
    });

    setSelectedCurr({
      label: offer.currency || "",
      value: offer.currency || ""
    });

    setIsTemplate({
      label: offer.isTemplate === true ? `${props.t("YES")}` : `${props.t("NO")}`,
      value: offer.isTemplate
    });

    setDescriptionTemplate({
      label: offer.descriptionTemplate && offer.descriptionTemplate.name || "",
      value: offer.descriptionTemplate
    });

    setIsEdit(true);
    toggle();
  };

  useEffect(() => {
    dispatch(onGetDescriptionTemplate());
  }, []);

  useEffect(() => {
    dispatch(onGetOfferItems());
  }, []);

  const { offerItems } = useSelector(state => ({
    offerItems: state.OfferItem.offerItems
  }));

  const offerItemOptions = [{ label: `${props.t("Create")}`, value: initialOfferItem }];
  offerItems.forEach((item) => {
    offerItemOptions.push({ label: item.name, value: { ...item, isTemplate: false } });
  });
  // Offer Columns
  const columns = useMemo(
    () => [
      {
        HeaderLabel: `${props.t("Name")}`,
        Header: "İsim",
        accessor: "name",
        filterable: true,
        Cell: (cellProps) => {
          return <Name {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Amount")}`,
        Header: "Amount",
        accessor: "amount",
        filterable: true,
        Cell: (cellProps) => {
          return <DefaultPrice {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Tax Amount")}`,
        Header: "Tax Amount",
        accessor: "taxAmount",
        filterable: true,
        Cell: (cellProps) => {
          return <DefaultPrice {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Currency")}`,
        Header: "Currency",
        accessor: "currency",
        filterable: true,
        Cell: (cellProps) => {
          return <Currency {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Total Amount")}`,
        Header: "Total Amount",
        accessor: "totalAmount",
        filterable: true,
        Cell: (cellProps) => {
          return <TotalPrice {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("isTemplate")}`,
        Header: "Template",
        accessor: "isTemplate",
        filterable: true,
        Cell: (cellProps) => {
          const row = cellProps.row.original;
          return (
            <div className="d-flex gap-3">
              {props.t(`${row !== null && row.isTemplate !== null && row.isTemplate === true ? "YES" : "NO"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("isDeletable")}`,
        Header: "Deletable",
        accessor: "deletable",
        filterable: true,
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {props.t(`${cellProps.row.original.deletable === false ? "Not Deletable" : "Deletable"}`)}
            </div>
          );
        }
      },
      {
        HeaderLabel: `${props.t("Note")}`,
        Header: "Note",
        accessor: "description",
        filterable: true,
        Cell: (cellProps) => {
          return <Description {...cellProps} />;
        }
      },
      {
        HeaderLabel: `${props.t("Action")}`,
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const offerData = cellProps.row.original;
                  handleOfferClick(offerData);
                }
                }
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  {props.t("Edit")}
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  const offerData = cellProps.row.original;
                  onClickDelete(offerData);
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  {props.t("Delete")}
                </UncontrolledTooltip>
              </Link>
            </div>
          );
        }
      }
    ],
    []
  );

  const handleChange = (e) => {
    if (e.target.name === "amount") {
      setOffer({
        ...offer,
        ["amount"]: e.target.value,
        ["totalAmount"]: e.target.value
      });
    } else {
      setOffer({
        ...offer,
        [e.target.name]: e.target.value
      });
    }
  };

  function onChangeTemplateOffer(e) {
    setIsTemplate(e);
    setOffer({
      ...offer,
      ["isTemplate"]: e.value
    });
  }

  const toggle = () => {
    if (modal) {
      setModal(false);
      setOffer(null);
      setIsTemplate("");
      setSelectedCurr(null);
    } else {
      setModal(true);
    }
  };

  //delete offer
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (offer) => {
    setOffer(offer);
    setDeleteModal(true);
  };

  const handleDeleteOffer = () => {
    if (offer.id) {
      dispatch(onDeleteOffer(offer.id));
      setDeleteModal(false);
    }
  };

  useEffect(() => {
    if (firstCall) {
      dispatch(onGetOffers());
      setFirstCall(false);
    }
  }, [dispatch, offers]);

  useEffect(() => {
    setOfferList(offers);
  }, [offers]);

  useEffect(() => {
    if (!isEmpty(offers)) {
      setOfferList(offers);
    }
  }, [offers]);

  const onChangeTaxPercentage = (e) => {
    setSelectedTax(e);
    if (e !== "" && e !== null) {
      setOfferItem({
        ...offerItem,
        ["taxPercentage"]: e.value
      });
    } else {
      setOfferItem({
        ...offerItem,
        ["taxPercentage"]: 0
      });
    }
  };

  useEffect(() => {
    calculateAllOfferFields();
  }, [offer && offer.offerItems]);

  useEffect(() => {
    if (offerItem.taxPercentage !== 0 && offerItem.amount !== 0 || offerItem.amount !== 0 && offerItem.taxAmount !== 0) {
      const taxAmount = ((offerItem.taxPercentage / 100) * offerItem.amount);
      const totalAmount = (+offerItem.amount + +taxAmount);
      setOfferItem({
        ...offerItem,
        ["taxAmount"]: taxAmount,
        ["totalAmount"]: totalAmount
      });
      setSelectedOfferItem({
        label: `${props.t("Create")}`,
        value: {
          ...offerItem.value,
          ["taxAmount"]: taxAmount,
          ["totalAmount"]: totalAmount
        }
      });
    } else {
      setOfferItem({
        ...offerItem,
        ["totalAmount"]: offerItem.amount
      });
      setSelectedOfferItem({
        label: `${props.t("Create")}`,
        value: {
          ...offerItem.value,
          ["totalAmount"]: offerItem.amount
        }
      });
    }
  }, [offerItem.taxAmount, offerItem.taxPercentage, offerItem.amount]);

  const handleChangeOfferItemField = (e) => {
    setOfferItem({
      ...offerItem,
      [e.target.name]: e.target.value
    });
    setSelectedOfferItem({
      label: `${props.t("Create")}`,
      value: {
        ...offerItem.value,
        [e.target.name]: e.target.value
      }
    });
  };

  function calculateAllOfferFields() {
    if (offer !== undefined && offer !== null && offer.offerItems && offer.offerItems.length >= 0) {
      let amount = 0;
      let totalAmount = 0;
      let taxPercentage = 0;
      let taxAmount = 0;
      offer.offerItems.forEach(offerItem => {
        amount = +offerItem.amount + +amount;
        totalAmount = +offerItem.totalAmount + +totalAmount;
        taxPercentage = offerItem.taxPercentage;
        taxAmount = +offerItem.taxAmount + +taxAmount;
      });

      setOffer({
        ...offer,
        ["amount"]: amount,
        ["totalAmount"]: totalAmount,
        ["taxPercentage"]: taxPercentage,
        ["taxAmount"]: taxAmount
      });
    }
  };

  useEffect(() => {
    if (offer) {
      calculateAllOfferFields();
    }
  }, [offer && offer.offerItems && offer.offerItems.length]);

  const handleOfferClicks = () => {
    setOfferList("");
    setIsEdit(false);
    toggle();
  };

  const handleRefresh = () => {
    dispatch(onGetOffers());
  };

  const handleClose = () => {
    setMessage("");
    setShowNotification(false);
  };

  useEffect(() => {
    if (error !== undefined && error.data !== undefined) {
      setMessage(error.data.message);
      setShowNotification(true);
      setNotificationType("Danger");
    }
  }, [error]);


  const onSelectCurr = (e) => {
    setSelectedCurr(e);
    setOffer({
      ...offer,
      currency: e.value.code
    });
  };

  const onClickRemoveFromList = (item) => {
    const list = offer.offerItems.filter(listItem => listItem !== item);
    setOffer({
      ...offer,
      offerItems: list
    });
  };

  const addOfferItemToListAndCreateEmptyOne = () => {
    const list = offer && offer.offerItems || [];
    list.push(offerItem);

    setOffer({
      ...offer,
      offerItems: list
    });
    setSelectedTax({ label: "", value: "" });
    setOfferItem(initialOfferItem);
    setSelectedOfferItem(null);
  };

  function findSymbol(currency) {
    const curr = currencies.filter(item => {
      if (item.code === currency) {
        return item.symbol;
      }
    });
    return curr[0].symbol;
  }

  const onChangeOfferItem = (e) => {
    setSelectedOfferItem(e);
    if (e !== "" && e !== null) {
      setOfferItem(e.value);
      setSelectedTax({ label: e.value.taxPercentage, value: e.value.taxPercentage });
    } else {
      setOfferItem(initialOfferItem);
      setSelectedTax(null);
    }
  };

  const { descriptionTemplates } = useSelector(state => ({
    descriptionTemplates: state.DescriptionTemplate.descriptionTemplates
  }));


  const descriptionTemplateOptions = [];
  descriptionTemplates.forEach(item => {
    descriptionTemplateOptions.push({
      label: item.name,
      value: item
    });
  });

  const handleChangeDescriptionTemplate = (e) => {
    setDescriptionTemplate(e);
    if (e !== null) {
      setOffer({
        ...offer,
        descriptionTemplate: e.value
      });
    }
  };

  return (
    <React.Fragment>
      <WarningModal
        show={deleteModal}
        onApproveClick={handleDeleteOffer}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
        <Container fluid>
          <Notification
            message={message}
            show={showNotification}
            callbackShow={handleClose}
            type={notificationType}
          />
          <Breadcrumbs title={props.t("Offer")} />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <TableContainer
                    refreshButton={true}
                    handleRefreshClick={handleRefresh}
                    columns={columns}
                    isShowRecord={false}
                    isColumnFilter={false}
                    data={offers}
                    isGlobalFilter={true}
                    isAddOptions={true}
                    handleAddClick={handleOfferClicks}
                    customPageSize={25}
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle} size="xl">
                    <ModalHeader toggle={toggle} tag="h4">
                      {!!isEdit
                        ? `${props.t("Edit")}`
                        : `${props.t("Add")}`}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit(validation.values);
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">{props.t("Name")}</Label>
                              <Input
                                name="name"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.name || ""}
                                invalid={!validation.values.name}
                                required={true}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Amount")}</Label>
                              <Input
                                name="amount"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.amount || ""}
                                invalid={!validation.values.amount}
                                disabled={true}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Tax Amount")}</Label>
                              <Input
                                name="taxAmount"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.taxAmount || ""}
                                disabled={true}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Total Amount")}</Label>
                              <Input
                                name="totalAmount"
                                type="text"
                                onChange={handleChange}
                                value={validation.values.totalAmount || ""}
                                invalid={!validation.values.totalAmount}
                                disabled={true}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Currency")} <i
                                className="mdi mdi-alert-circle-outline error font-size-16 me-1" /></Label>
                              <Select
                                value={selectedCurr}
                                onChange={(event) => onSelectCurr(event)}
                                options={currencies.map(curr => {
                                  return { label: curr.code, value: curr };
                                })}
                                className="bs-select"
                                isClearable={true}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Template")}</Label>
                              <Select
                                id="isTemplate"
                                name="template-offer"
                                onChange={e => onChangeTemplateOffer(e)}
                                value={isTemplate}
                                options={[{
                                  label: `${props.t("YES")}`,
                                  value: true
                                }, { label: `${props.t("NO")}`, value: false }]}
                              />
                            </div>

                            <div className="mb-3">
                              <Label className="form-label">{props.t("Note")}</Label>
                              <Input
                                name="description"
                                type="text"
                                onChange={handleChange}
                                placeholder={props.t("This field will not be shown in the offer")}
                                value={validation.values.description || ""}
                              />
                            </div>

                            <div className="mb-3 col-md-12 col-xs-12">
                              <Label className="form-label">{props.t("Description Template")}</Label>
                              <Select
                                value={descriptionTemplate}
                                onChange={(event) => handleChangeDescriptionTemplate(event)}
                                options={descriptionTemplateOptions}
                                className="bs-select"
                                isClearable={true}
                              />
                            </div>

                            <div className="mb-3">
                              <Row>
                                <Col className="col-2">
                                  <Label className="bs-select">{props.t("Choose Offer Item")} :</Label>
                                  <Select
                                    value={selectedOfferItem}
                                    onChange={(event) => onChangeOfferItem(event)}
                                    options={offerItemOptions}
                                    className="bs-select"
                                    isClearable={true}
                                  />
                                </Col>
                                <Col className="col-9">
                                  <Row>
                                    <Col className="col-3">
                                      <Label className="form-label">{props.t("Name")}</Label>
                                      <Input
                                        name="name"
                                        onChange={handleChangeOfferItemField}
                                        value={offerItem && offerItem.name || ""}
                                        invalid={offerItem.name === ""}
                                      />
                                    </Col>

                                    <Col className="col-2">
                                      <Label className="form-label">{props.t("Amount")}</Label>
                                      <Input
                                        name="amount"
                                        type="text"
                                        onChange={handleChangeOfferItemField}
                                        value={offerItem && offerItem.amount || ""}
                                      />
                                    </Col>

                                    <Col className="col-2">
                                      <Label className="form-label">{props.t("Tax Percentage")} (%)</Label>
                                      <Select
                                        value={selectedTax}
                                        onChange={(event) => onChangeTaxPercentage(event)}
                                        options={[{ label: "10", value: 10 }, { label: "20", value: 20 }]}
                                        className="bs-select"
                                        isClearable={true}
                                      />
                                    </Col>

                                    <Col className="col-2">
                                      <Label className="form-label">{props.t("Tax Amount")}</Label>
                                      <Input
                                        name="taxAmount"
                                        type="text"
                                        onChange={handleChangeOfferItemField}
                                        disabled={true}
                                        value={offerItem && offerItem.taxAmount || ""}
                                      />
                                    </Col>

                                    <Col className="col-3">
                                      <Label className="form-label">{props.t("Total Amount")}</Label>
                                      <Input
                                        name="totalAmount"
                                        type="text"
                                        onChange={handleChangeOfferItemField}
                                        value={offerItem && offerItem.totalAmount || ""}
                                        invalid={offerItem.totalAmount === 0}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <Col className="col-1 text-end mt-4">
                                  <Button
                                    color="warning"
                                    outline
                                    onClick={addOfferItemToListAndCreateEmptyOne}
                                    disabled={offerItem.name === "" || offerItem.totalAmount === ""}
                                  >
                                    <i className="mdi mdi-plus-circle font-size-16" id="addToList">
                                      {props.t("Add")}
                                    </i>
                                  </Button>
                                </Col>
                              </Row>
                            </div>

                            {offer && offer.offerItems && offer.offerItems.length > 0 && (
                              <Table className="table-centered table-nowrap">
                                <thead>
                                <tr>
                                  <th scope="col">{props.t("Item")}</th>
                                  <th scope="col">{props.t("Amount")}</th>
                                  <th scope="col">{props.t("Tax Amount")}</th>
                                  <th scope="col">{props.t("Total Amount")}</th>
                                  <th scope="col-1">{props.t("Action")}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {offer.offerItems.map((item, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{item.name}</td>
                                      <td>{item.amount} {offer.currency && findSymbol(offer.currency) || ""}</td>
                                      <td>{item.taxAmount} {offer.currency && findSymbol(offer.currency) || ""}</td>
                                      <td>{item.totalAmount} {offer.currency && findSymbol(offer.currency) || ""}</td>
                                      <td>
                                        <div className="flex-md-wrap">
                                          <Button
                                            color="danger"
                                            outline
                                            onClick={() => onClickRemoveFromList(item)}
                                          >
                                            <i className="mdi mdi-minus-circle font-size-16" id="removeTooltip" />
                                            <UncontrolledTooltip placement="top" target="removeTooltip">
                                              {props.t("Remove From List")}
                                            </UncontrolledTooltip>
                                          </Button>
                                        </div>
                                      </td>
                                    </tr>);
                                })
                                }
                                </tbody>
                              </Table>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-lead"
                                disabled={selectedCurr === null || selectedCurr.value === "" || offer.offerItems === undefined || offer.offerItems.length === 0}
                              >
                                {props.t("Save")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
Offer.propTypes = {
  offers: PropTypes.array,
  onGetOffers: PropTypes.func,
  onAddNewOffer: PropTypes.func,
  onDeleteOffer: PropTypes.func,
  onUpdateOffer: PropTypes.func
};

export default withRouter(withTranslation()(Offer));

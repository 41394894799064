import {
  LEAD_REPORT_BY_USER,
  LEAD_REPORT_BY_USER_SUCCESS,
  LEAD_REPORT_BY_USER_FAIL, LEAD_COUNTS_BY_SEGMENT_SUCCESS, LEAD_COUNTS_BY_SEGMENT_FAIL
} from "./actionTypes";

const INIT_STATE = {
  reports: [],
  segmentReports: [],
  error: {},
};

const Report = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LEAD_REPORT_BY_USER_SUCCESS:
      return {
        ...state,
        reports: action.payload
      };

    case LEAD_REPORT_BY_USER_FAIL:
      return {
        ...state,
        error: action.payload
      };

    case LEAD_COUNTS_BY_SEGMENT_SUCCESS:
      return {
        ...state,
        segmentReports: action.payload
      };

    case LEAD_COUNTS_BY_SEGMENT_FAIL:
      return {
        ...state,
        error: action.payload
      };

    default:
      return {
        ...state,
      };
  }
};

export default Report;
